.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-inline: 30px;
  margin-block: 16px;
  padding-top: 1rem;

  .burgerMenu {
    width: 37px;
    height: 22px;
  }

  .logo {
    width: 120px; // Adjust width as needed, maintaining the logo's aspect ratio
    position: absolute; // Absolute positioning to center the logo
    left: 50%; // Combined with transform to center horizontally
    transform: translateX(-50%); // Centers the logo
  }

  .userActions {
    display: flex;
    align-items: center;

    .signIn {
      border-radius: 20px;
      padding: 8px 16px;
      background-color: #007bff; // adjust this
      color: #fff;
      border: none;
    }

    .userCircle {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #007bff; // adjust this
      color: #fff;
    }
  }
}
