.dark {
  --page-bg-color: #040024;
  --logo-gradient-start: #8c9ff3;
  --logo-gradient-end: #9a82de;
  --primary-bg-color: #333;
  --primary-text-color: #fff;
  --meta-text-color: #a6a6a6;
  --meta-font-family: "Alegreya Sans", sans-serif;
  --footer-bg-color: #8c9ff3;
}
