.storyView {
  display: flex;
  flex-direction: column;
  margin-block: 50px;
  margin-inline: 15px;
  padding: 20px;
  border: 2px solid #e1e1e1;
  border-radius: 30px; // Rounded borders
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .storyHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .storyTitle {
    font-size: 28px;
    font-weight: 400;
    font-family: "Cormorant Garamond";
  }

  .storyRating {
    font-size: 16px;
    color: #333;
  }

  .storyTags {
    margin-top: 8px;
    display: flex;
    gap: 8px; // Spacing between tags
    font-family: "Alegreya Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    color: var(--meta-text-color);

    .tag {
      padding: 4px 8px;
    }
  }

  .storyPreview {
    margin-block: 16px;
    font-size: 14px;
    font-family: "Alegreya Sans", sans-serif;
    font-weight: 400;
    line-height: 25.2px;
  }

  .continueReadingBtn {
    width: 100%;
    height: 40px;
    margin-top: 15px;
    align-self: center; // Center the button
    padding: 8px 16px;
    border: none;
    border-radius: 30px;
    background: linear-gradient(90.99deg, #8c9ff3 0%, #9a82de 100%);
    border-color: transparent;
    color: var(--primary-text-color);
    font-family: "Alegreya Sans", sans-serif;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }
}
