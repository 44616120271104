@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;400;700&display=swap");

.logo {
  font-family: "Lexend", sans-serif; // Assuming you've imported the Lexend font elsewhere in your project
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 120px;
  text-align: center;

  width: 287px;
  height: 120px;
  left: calc(50% - 287px / 2 + 1.5px);

  // Use CSS variables for the gradient
  background: linear-gradient(
    99.92deg,
    var(--logo-gradient-start) 0%,
    var(--logo-gradient-end) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  //text-fill-color: transparent;
}
