.footer {
  width: 100%;
  left: 0;
  bottom: 0;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 30px;
  box-sizing: border-box;
  justify-content: space-between;

  background-color: var(--footer-bg-color);

  .icons {
    width: 100%; // Use full width on mobile
    display: flex;
    justify-content: center;
    margin-bottom: 10px; // Some margin for spacing
  }

  .links {
    width: 100%; // Use full width on mobile
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; // Some margin for spacing
  }

  @media (min-width: 768px) {
    // This is an arbitrary breakpoint for tablets/desktops
    height: 75px; // Desktop height
  }
}

.iconLink img {
  width: 24px; // Adjust size if necessary
  height: 24px; // Adjust size if necessary
  margin: 0 5px;
}

.footerButton {
  font-family: "Alegreya Sans", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  background-color: transparent;
  border: none; // Removes default button border
  border-bottom: 2px solid transparent; // Initially transparent bottom border
  transition: border-color 0.3s ease; // Smooth transition for hover effect
  border-bottom-color: #9a82de;
  padding: 8px 0px;
  font-weight: 700;
  margin: 0 3px;

  &:hover,
  &:focus {
    border-bottom-color: #ffffff; // Changes the color of the bottom border on hover/focus
  }
}
