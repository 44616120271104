.ratingContainer {
  display: flex;
  align-items: center;
}

.ratingNumber {
  margin-right: 4px; // Adjust the space as needed
  font-size: 24px; // Adjust the font size as needed
  font-family: "Cormorant Garamond";
  color: var(--primary-text-color);
}

.ratingIcon {
  color: #ff7c48; // Adjust the color as needed
  font-size: 18px; // Adjust the icon size as needed
}
