.storyContainer {
  margin: 0 30px;

  .goBackButton {
    top: 10px;
    left: 10px;
    z-index: 10;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    color: inherit; // Ensures the icon takes the color of its surroundings
    margin-top: 30px;
    padding: 0;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      border-radius: 50%;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    flex: 1;
    text-align: left;
    font-family: "Cormorant Garamond";
    font-size: 28px;
    font-weight: 400;
  }

  .tagsContainer {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .tag {
      color: var(--primary-text-color);
      font-family: "Alegreya Sans";
      border: 2px solid;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      border-color: var(--logo-gradient-end);
      border-radius: 20px;
      width: 90px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .content {
    margin-top: 50px;
    font-family: "Alegreya Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
  }

  .authorName {
    margin-top: 100px;
    color: var(--primary-text-color);
    font-family: "Alegreya Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
}
