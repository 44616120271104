@import './themes/dark.theme.scss';
@import './themes/light.theme.scss';

body,
.page-content {
  background-color: var(--page-bg-color);
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-container {
  flex: 1;
}