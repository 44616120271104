@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;700&display=swap");

.drawerBackground.drawerBackground {
  background-color: var(--page-bg-color);
}

.whiteText {
  color: var(--primary-text-color);
  font-family: "Cormorant Garamond", serif !important; // Note: Ensure the font is imported or available in your project
  font-size: 18px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
}

.closeIcon {
  color: var(--primary-text-color);
  cursor: pointer;
}

.gradientDivider {
  height: 1px;
  width: 90%;
  margin: 0 auto; /* Center the divider */
  background: linear-gradient(
    90deg,
    var(--logo-gradient-start) 0%,
    var(--logo-gradient-end) 100%
  );
}

.socialMediaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute; // Add this to position the container
  bottom: 0; // Stick to the bottom
  left: 0; // Stretch across the entire width
  right: 0; // Stretch across the entire width
  padding-bottom: 16px;
  flex-direction: row; // Change from column to row
}

.socialMediaIcon {
  fill: var(--primary-text-color);
  margin: 0 8px;
  width: 24px; // Adjust as necessary
  height: 24px;
}
