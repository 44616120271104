.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.title {
  font-size: 1.5em;
  margin-bottom: 16px;
}

.motto {
  font-size: 30px;
  font-family: "Cormorant Garamond";
  text-align: center;
}

.title {
  font-size: 30px;
  font-family: "Cormorant Garamond";
  text-align: center;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 22px;
}

.description {
  font-size: 20px;
  font-family: "Cormorant Garamond";
  text-align: center;
  margin-inline: 80px;
  margin-top: 50px;
}

.content {
  font-size: 16px;
  font-family: "Alegreya Sans", sans-serif;
  font-weight: 400;
  line-height: 29.97px;
  text-align: center;
  margin-inline: 10px;
}

.moreContentBtn {
  margin-block: 50px;
  padding: 17px 31px;
  align-self: center; // Center the button
  border: none;
  border-radius: 30px;
  background: linear-gradient(90.99deg, #8c9ff3 0%, #9a82de 100%);
  border-color: transparent;
  color: var(--primary-text-color);
  font-family: "Alegreya Sans", sans-serif;
  font-size: 26px;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
}

.iconContainer {
  // Optional: set a specific width or other styles for the container
  margin-inline: 10px;

  .iconRow {
    display: flex;
    align-items: center; // Vertically centers the icon and text in each row
    margin-bottom: 10px; // Adds space between the rows

    &:last-child {
      margin-bottom: 0; // Removes margin from the last row
    }

    .icon {
      // Optional: set a specific size for the icons
      width: 85px;
      height: 85px;
      margin-bottom: 24px; // Adds space between the icon and its text
      margin-right: 41px;
    }
  }
}
